import { config } from 'config';
import Link from 'next/link';

const categoriesOnStartPage = ['racing', 'obbys', 'shooter', '2d-games', 'hangouts', 'teamwork'];
export const categories = [
  {
    id: 'racing',
    title: 'Racing',
    fullTitle: 'Racing Games',
    description: 'Feel the rush of adrenaline as you race to victory!',
    color: '#E9A904',
    bannerImage: `${config.cdn}/static/images/category_banner_racing_01.png`,
    metaTag: 'category_racing',
    thumbnail: `${config.cdn}/static/images/category_racing_01.png`,
    url: '/category/racing',
  },
  {
    id: 'obbys',
    title: 'Obbys',
    fullTitle: 'Obby Games',
    color: '#DF3785',
    description: 'Dodge, jump, and conquer obstacles in these obbys!',
    bannerImage: `${config.cdn}/static/images/category_banner_obbys_01.png`,
    metaTag: 'category_obby',
    thumbnail: `${config.cdn}/static/images/category_obbys_01.png`,
    url: '/category/obbys',
  },
  {
    id: 'shooter',
    title: 'Shooter',
    fullTitle: 'Shooter Games',
    color: '#01C67F',
    description: 'Jump into quick battles and show everyone your sharpshooting skills!',
    bannerImage: `${config.cdn}/static/images/category_banner_shooter_01.png`,
    metaTag: 'category_shooter',
    thumbnail: `${config.cdn}/static/images/category_shooter_01.png`,
    url: '/category/shooter',
  },
  {
    id: 'flying',
    title: 'Flying',
    fullTitle: 'Flying Games',
    color: '#01C67F',
    description: 'Lock and load for fast, thrilling action in this awesome shooter game!',
    bannerImage: `${config.cdn}/static/images/category_banner_flying_01.png`,
    metaTag: 'category_flying',
    thumbnail: `${config.cdn}/static/images/category_flying_01.png`,
    url: '/category/flying',
  },

  {
    id: '2d-games',
    title: '2D Games',
    fullTitle: '2D Games',
    color: '#8333F9',
    description: 'Dive into the world of 2D gaming with our diverse collection of pixel-perfect challenges!',
    bannerImage: `${config.cdn}/static/images/category_banner_2dgames_01.png`,
    metaTag: 'category_2d',
    thumbnail: `${config.cdn}/static/images/category_2dgames_01.png`,
    url: '/category/2d-games',
  },
  {
    id: 'hangouts',
    title: 'Hangouts',
    fullTitle: 'Hangout Games',
    color: '#F27405',
    description: 'Connect, chat, and chill with friends!',
    bannerImage: `${config.cdn}/static/images/category_banner_hangouts_01.png`,
    metaTag: 'category_hangout',
    thumbnail: `${config.cdn}/static/images/category_hangouts_01.png`,
    url: '/category/hangouts',
  },
  {
    id: 'teamwork',
    title: 'Teamwork',
    fullTitle: 'Teamwork Games',
    color: '#04BFAD',
    description: 'Collaborate, strategize, and conquer together in challenging teamwork games!',
    bannerImage: `${config.cdn}/static/images/category_banner_teamwork_01.png`,
    metaTag: 'category_teamwork',
    thumbnail: `${config.cdn}/static/images/category_teamwork_01.png`,
    url: '/category/teamwork',
  },
];

export const Categories = () => {
  return (
    <div className="overflow-x-auto">
      <div className="grid gap-[0.625rem] mediumLarge:gap-4 grid-cols-6 min-w-[768px]">
        {categories
          .filter(category => categoriesOnStartPage.includes(category.id))
          .map(category => {
            return (
              <Link key={category.title} href={category.url}>
                <div className="overflow-hidden rounded-lg">
                  <div
                    className={`relative aspect-square bg-cover transition-transform will-change-transform hover:scale-105`}
                    style={{ backgroundImage: `url('${category.thumbnail}')`, backgroundColor: category.color }}>
                    <label className="text-button_medium mediumLarge:text-h4 font-semibold absolute left-[11%] top-[11%]">
                      {category.title}
                    </label>
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
};
