import { Button } from 'components/common/buttons/Button';
import { Heading1, Paragraph } from 'components/typography';
import { useDive } from 'hooks/useDive';
import { usePathname } from 'next/navigation';
import { Modal } from '../Modal';
import { InformationalModalProps } from '../Modal.types';

export const InformationModal = ({
  close,
  text,
  headline,
  onConfirm,
  confirmButtonText,
  children,
  trackButtonName,
}: InformationalModalProps) => {
  const dive = useDive();
  const pathname = usePathname() ?? '';
  const confirm = () => {
    dive.trackUserInteract(pathname, '', 'information_modal_accept', 'button', trackButtonName ?? headline ?? '');
    onConfirm?.();
    close?.();
  };
  return (
    <Modal
      onClose={() => {
        dive.trackUserInteract(pathname, '', 'information_modal_close', 'button', trackButtonName ?? headline ?? '');
      }}>
      {Boolean(headline) && <Heading1 className="text-h2 text-center mb-8">{headline}</Heading1>}
      {Boolean(text) && (
        <Paragraph size="medium" className="mb-4">
          {text}
        </Paragraph>
      )}
      {Boolean(children) && children}
      <div className="flex justify-center mt-8">
        <Button variety="primary" size="medium" text={confirmButtonText ?? 'OK'} onClick={confirm} />
      </div>
    </Modal>
  );
};
