class SeededRNG {
  private seed: number;

  constructor(seed?: string) {
    this.seed = seed ? this.stringToSeed(seed) : Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
  }

  // Create a seed number based on the string provided
  private stringToSeed(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return hash < 0 ? -hash : hash; // Ensure seed is positive
  }

  // Minimal standard LCG (Linear Congruential Generator)
  public next(): number {
    const a = 1664525;
    const c = 1013904223;
    const m = 4294967296; // 2^32
    this.seed = (a * this.seed + c) % m;
    return this.seed / m;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const shuffleArray = <T>(array: T[], seed?: string): T[] => {
  const rng = new SeededRNG(seed);
  const result = [...array]; // Clone the original array to avoid mutation
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(rng.next() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]]; // Swap elements
  }
  return result;
};
