import { Link } from 'components/common/Link';
import { Label } from 'components/typography';
import { ProjectActiveFriend } from 'hooks/useActiveFriends';
import Image from 'next/image';

interface OnlinePlayersProps {
  label: string;
  friends?: ProjectActiveFriend[];
  color?: 'red' | 'green';
}

export const OnlinePlayers = ({ label, color, friends = [] }: OnlinePlayersProps) => {
  return (
    <div className="bg-[rgba(52,54,60,0.25)] pl-[12px] px-[18px] m-2 flex self-start items-center rounded-3xl backdrop-blur-[8px] h-10 [text-shadow:1px_1px_3px_rgba(0,0,0,0.25)]">
      <div className="flex gap-2 items-center">
        <div className="flex size-6 justify-center items-center relative" data-testid="online-indicator">
          <div
            data-red={color === 'red'}
            className="size-2 rounded-full border-4 bg-accents-green-primary data-[red=true]:bg-primary-main absolute"
          />
          <div
            data-red={color === 'red'}
            className="absolute size-2 bg-transparent rounded-full border-accents-green-primary data-[red=true]:border-primary-main border-[1px] border-solid animate-[ringPulse_2s_infinite] [animation-delay:0.0s]"
          />
          <div
            data-red={color === 'red'}
            className="absolute size-2 bg-transparent rounded-full border-accents-green-primary data-[red=true]:border-primary-main border-[1px] border-solid animate-[ringPulse_2s_infinite] [animation-delay:0.6s]"
          />
        </div>
        <Label size="large">{label}</Label>
        {friends.map(friend => (
          <Link href={`/user/${friend.username}`} key={`playing-friend-${friend.username}`}>
            <Image
              className="rounded-full"
              height={25}
              width={25}
              key={friend.username}
              src={friend.pictureUrl}
              alt={friend.username}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};
