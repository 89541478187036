import DangerousHtmlContent from 'components/common/DangerousHtmlContent';
import { Button } from 'components/common/buttons/Button';
import { Spacer } from 'components/layouts/Spacer';
import { InformationModal } from 'components/legacy/modals/InformationModal';
import { ErrorMessage, Heading2 } from 'components/typography';
import { useLatestNewsQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useModal } from 'lib/useModal';
import { usePathname, useRouter } from 'next/navigation';
import { NewsFeedLoader } from './NewsFeed.loader';
import Image from 'next/image';

export const NewsFeed = ({ name }: { name: string }) => {
  const news = useLatestNewsQuery();
  const router = useRouter();
  const dive = useDive();
  const pathname = usePathname() ?? '';

  const regExp = /hiberworld|teamhiber|hiberdev/g;
  const isInternalLink = regExp.exec(news.data?.latestNewsArticle?.ctaButtonLink ?? '');
  const linkTarget = isInternalLink ? '_self' : '_blank';
  const trackId = news.data?.latestNewsArticle?.title;
  const latestNewsArticle = news.data?.latestNewsArticle;

  const navigate = () => {
    if (!latestNewsArticle?.ctaButtonLink) {
      return;
    }

    if (isInternalLink) {
      router.push(latestNewsArticle.ctaButtonLink);
      return;
    }

    window.open(latestNewsArticle.ctaButtonLink, linkTarget);
  };

  const onModalConfirm = () => {
    if (!latestNewsArticle?.ctaButtonLink) {
      return;
    }
    navigate();
  };

  const infoModal = useModal(
    <InformationModal
      headline={news.data?.latestNewsArticle?.modalTitle}
      confirmButtonText={news.data?.latestNewsArticle?.modalButtonText}
      onConfirm={onModalConfirm}
      trackButtonName={news.data?.latestNewsArticle?.title}>
      <DangerousHtmlContent html={news.data?.latestNewsArticle?.modalText} />
    </InformationModal>
  );

  if (news.error) {
    return <ErrorMessage message={news.error.message} />;
  }

  if (news.loading) {
    return <NewsFeedLoader />;
  }

  if (!latestNewsArticle) {
    return null;
  }

  const handleButtonClick = () => {
    dive.trackUserInteract(pathname, '', name, 'button', trackId);
    if (latestNewsArticle.openModal) {
      infoModal.open();
      return;
    }
    navigate();
  };

  const handleClick = () => {
    if (latestNewsArticle.openModal) {
      return;
    }

    handleButtonClick();
  };

  return (
    <div
      className="bg-greyscale-veryDark grid grid-cols-[1fr] mediumLarge:grid-cols-[1fr_1fr] rounded-lg overflow-hidden"
      aria-label="News container">
      <div className="overflow-hidden flex h-full" onClick={handleClick}>
        <Image
          className="overflow-hidden medium:object-cover object-contain object-top medium:w-[auto] w-full medium:h-full h-[auto]"
          src={latestNewsArticle.thumbnail?.url ?? ''}
          height={400}
          width={700}
          alt="News"
          priority
        />
      </div>
      <div className="flex py-4 medium:px-16 px-4 justify-center flex-col items-start">
        <Heading2 onClick={handleClick}>{latestNewsArticle.title}</Heading2>
        <Spacer height="1rem" />
        <DangerousHtmlContent html={latestNewsArticle.article} />
        <Spacer height="1rem" />
        <Button
          variety="primary"
          size="medium"
          text={latestNewsArticle.ctaButtonText ?? ''}
          onClick={handleButtonClick}
        />
      </div>
    </div>
  );
};

export default NewsFeed;
