import { FeedFilter } from 'generated/graphql';
import dynamic from 'next/dynamic';
import { twc } from 'react-twc';
import { ProjectFeedRegularModule } from 'views/pages/start/ProjectFeed/ProjectFeedRegular';
import { ProjectFeedSpecificModule } from 'views/pages/start/ProjectFeed/ProjectFeedSpecific';
import { Banner } from './Banner/Banner';
import { CarouselBanner } from './CarouselBanner/CarouselBanner';
import { ChallengeBanner } from './ChallengeBanner/ChallengeBanner';
import { Hero } from './Hero/Hero';
import { NewsFeed } from './NewsFeed/NewsFeed';
import { NewsFeedLoader } from './NewsFeed/NewsFeed.loader';
import { PageModuleProps } from './PageModule.types';
import { ProjectFeedLoader } from './ProjectFeed/ProjectFeedLoader';
import { ProjectFeedRecommendedModule } from './ProjectFeed/ProjectFeedRecommended';
import { SessionFeed } from './SessionFeed/SessionFeed';
import { UserFeed } from './UserFeed/UserFeed';
import { UserFeedOnlineFriends } from './UserFeed/UserFeedFriendsOnline';
import { Categories } from './Categories/Categories';

export const ModuleWrapper = twc.div`
p-4 w-full max-w-maxContent medium:px-8 large:px-16
`;

export const PageModule = ({ module, index }: PageModuleProps) => {
  const type = module.__typename;
  const shouldRenderServerSide = index < 3;

  switch (type) {
    case 'Hero': {
      let Comp = dynamic(() => import('./Hero/Hero'), { ssr: false });

      if (shouldRenderServerSide) {
        Comp = Hero;
      }
      return <Comp module={module} />;
    }

    case 'Carousel': {
      let Comp = dynamic(() => import('./CarouselBanner/CarouselBanner'), { ssr: false });

      if (shouldRenderServerSide) {
        Comp = CarouselBanner;
      }
      return (
        <div className="flex justify-center">
          <ModuleWrapper>
            <Comp module={module} shouldRenderServerSide={shouldRenderServerSide} />
          </ModuleWrapper>
        </div>
      );
    }

    case 'Banner': {
      let Comp = dynamic(() => import('./Banner/Banner'), { ssr: false });

      if (shouldRenderServerSide) {
        Comp = Banner;
      }

      return (
        <div className="flex justify-center">
          <ModuleWrapper>
            <Comp module={module} name={`${index}_${module.header}`} imageHasPriority={shouldRenderServerSide} />
          </ModuleWrapper>
        </div>
      );
    }

    case 'ChallengeBanner': {
      let Comp = dynamic(() => import('./ChallengeBanner/ChallengeBanner'), { ssr: false });

      if (shouldRenderServerSide) {
        Comp = ChallengeBanner;
      }
      return (
        <div className="flex justify-center">
          <ModuleWrapper>
            <Comp module={module} name={`${index}_latest_challenge_banner`} />
          </ModuleWrapper>
        </div>
      );
    }

    case 'NewsFeed': {
      // eslint-disable-next-line react/no-unstable-nested-components
      let Comp = dynamic(() => import('./NewsFeed/NewsFeed'), { ssr: false, loading: () => <NewsFeedLoader /> });

      if (shouldRenderServerSide) {
        Comp = NewsFeed;
      }
      return (
        <div className="flex justify-center">
          <ModuleWrapper>
            <Comp name={`${index}_news_banner`} />
          </ModuleWrapper>
        </div>
      );
    }
    case 'ProjectFeedRegular': {
      let Comp = dynamic(() => import('./ProjectFeed/ProjectFeedRecommended'), {
        ssr: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        loading: () => <ProjectFeedLoader quantity={module.quantity} layout={module.layout} />,
      });

      if (shouldRenderServerSide) {
        Comp = ProjectFeedRecommendedModule;
      }

      const isRecommendedFeed =
        module.page?.filters?.includes(FeedFilter.Recommended) || module?.filters?.includes(FeedFilter.Recommended);
      if (isRecommendedFeed) {
        return (
          <div className="flex justify-center">
            <ModuleWrapper>
              <Comp module={module} feedIndex={index} />
            </ModuleWrapper>
          </div>
        );
      }

      let Comp2 = dynamic(() => import('views/pages/start/ProjectFeed/ProjectFeedRegular'), {
        ssr: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        loading: () => <ProjectFeedLoader quantity={module.quantity} layout={module.layout} />,
      });

      if (shouldRenderServerSide) {
        Comp2 = ProjectFeedRegularModule;
      }

      return (
        <div className="flex justify-center">
          <ModuleWrapper>
            <Comp2 module={module} feedIndex={index} />
          </ModuleWrapper>
        </div>
      );
    }
    case 'ProjectFeedSpecific': {
      let Comp = dynamic(() => import('views/pages/start/ProjectFeed/ProjectFeedSpecific'), {
        ssr: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        loading: () => <ProjectFeedLoader quantity={module.projectIds.length} layout={module.layout} />,
      });

      if (shouldRenderServerSide) {
        Comp = ProjectFeedSpecificModule;
      }

      return (
        <div className="flex justify-center">
          <ModuleWrapper>
            <Comp module={module} feedIndex={index} />
          </ModuleWrapper>
        </div>
      );
    }
    case 'SessionFeed': {
      let Comp = dynamic(() => import('./SessionFeed/SessionFeed'), { ssr: false });

      if (shouldRenderServerSide) {
        Comp = SessionFeed;
      }
      return (
        <div className="flex justify-center">
          <ModuleWrapper>
            <Comp module={module} feedIndex={index} firstImageHasPriority={shouldRenderServerSide} />
          </ModuleWrapper>
        </div>
      );
    }
    case 'UserFeedOnlineFriends': {
      let Comp = dynamic(() => import('./UserFeed/UserFeedFriendsOnline'), { ssr: false });

      if (shouldRenderServerSide) {
        Comp = UserFeedOnlineFriends;
      }
      return <Comp module={module} feedIndex={index} />;
    }
    case 'UserFeedRegular': {
      let Comp = dynamic(() => import('./UserFeed/UserFeed'), { ssr: false });

      if (shouldRenderServerSide) {
        Comp = UserFeed;
      }
      return (
        <div className="flex justify-center">
          <ModuleWrapper>
            <Comp module={module} />
          </ModuleWrapper>
        </div>
      );
    }

    case 'Section': {
      if (module.title === 'Category') {
        return (
          <div className="flex justify-center">
            <ModuleWrapper>
              <Categories />
            </ModuleWrapper>
          </div>
        );
      }
      return null;
    }

    default: {
      const neverHappens: never = type;
      console.error(`Unknown module type: ${neverHappens}`);

      return null;
    }
  }
};
