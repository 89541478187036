import Link from 'next/link';
import React from 'react';
import { UserLinkProps } from './Links.types';
import * as S from './Links.styles';

export const UserLink: React.FC<UserLinkProps> = ({ username, children, overrideColor, bold }) => {
  return (
    <Link href={`/user/${username}`} passHref legacyBehavior prefetch={false}>
      <S.Container bold={bold} data-testid="user-link" overrideColor={overrideColor}>
        {children}
      </S.Container>
    </Link>
  );
};
