import { LoadingCard } from 'components/common/SkeletonLoader';
import { Spacer } from 'components/layouts/Spacer';
import { Heading2 } from 'components/typography';
import { Project, Session, SessionType, useOnlineFriendsQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { ENGINE_TRACK_URL } from 'hooks/useDive/useDive.constants';
import { usePathname } from 'next/navigation';
import { ModuleWrapper } from '../PageModule';
import { PageModuleTypeProp } from '../PageModule.types';
import { OnlineFriend } from './OnlineFriend';
import * as S from './OnlineFriends.styles';
import { PlayingFriend } from './PlayingFriend';

type JoinableSession = Pick<Session, 'type'> & { project: Pick<Project, 'visibility'> };
const joinable = (session: JoinableSession) =>
  session?.type !== SessionType.Creative && session?.project?.visibility === 2;

export const UserFeedOnlineFriends = ({
  module,
  feedIndex,
}: {
  module: PageModuleTypeProp<'UserFeedOnlineFriends'>;
  feedIndex?: number;
}) => {
  const dive = useDive();
  const pathname = usePathname() ?? '';

  const { data, loading } = useOnlineFriendsQuery({ fetchPolicy: 'cache-only' });
  const friends = data?.onlineFriends;

  const trackUserCardClick = (userId: string, destination: string, name) => {
    dive.track('userInteract', {
      page_id: destination,
      page_id_before: pathname,
      name,
      type: 'feed',
      id: userId,
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center">
        <ModuleWrapper>
          <S.OuterRow>
            <div className="flex justify-center">
              <Heading2 className="text-h3">{module.title}</Heading2>
            </div>
            <Spacer height={24} />
            <S.Row>
              {new Array(7).fill(0).map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <LoadingCard height={140} width={190} borderRadius={8} key={`OnlineFriendLoading-${i}`} />
              ))}
            </S.Row>
          </S.OuterRow>
        </ModuleWrapper>
      </div>
    );
  }

  if (!friends || friends.length === 0) {
    return null;
  }

  // Sort friends by preferring the existence of multiplayer session.
  const sortedFriends = friends.slice().sort((f1, f2) => +!!f2.user.activeSession - +!!f1.user.activeSession);

  return (
    <div className="flex justify-center">
      <ModuleWrapper>
        <S.OuterRow>
          <div className="flex justify-center">
            <Heading2>{module.title}</Heading2>
          </div>
          <Spacer height={24} />
          <S.Row>
            {sortedFriends.map(({ user }, index) => {
              const { username, pictureUrl, isEmployee, isVerified } = user;
              const showPlaying = Boolean(
                user?.activeSession &&
                  joinable(user?.activeSession) &&
                  user?.activeSession?.players &&
                  user.activeSession.players?.length > 0
              );
              const name = `${feedIndex && feedIndex + '_'}${module.title}_${index}`;

              if (showPlaying) {
                return (
                  <PlayingFriend
                    onSessionClick={() => trackUserCardClick(user.id, ENGINE_TRACK_URL, name)}
                    onProjectClick={() => trackUserCardClick(user.id, ENGINE_TRACK_URL, name)}
                    onUserClick={() => trackUserCardClick(user.id, ENGINE_TRACK_URL, name)}
                    user={user}
                    key={`PlayingFriend-${user.id}`}
                  />
                );
              }
              return (
                <OnlineFriend
                  onUserClick={() => trackUserCardClick(user.id, `/user/${user.username}`, name)}
                  username={username}
                  pictureUrl={pictureUrl}
                  isEmployee={isEmployee}
                  isVerified={isVerified}
                  key={`OnlineFriend-${user.id}`}
                />
              );
            })}
          </S.Row>
        </S.OuterRow>
      </ModuleWrapper>
    </div>
  );
};

export default UserFeedOnlineFriends;
