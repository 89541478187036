import { Button } from 'components/common/buttons/Button';
import { Spinner } from 'components/common/spinners/Spinner';
import { Heading2, Paragraph } from 'components/typography';
import { useLatestHiberChallengeQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useGetOrCreateDaoSession } from 'hooks/useGetOrCreateDaoSession';
import { usePathname, useRouter } from 'next/navigation';
import { isExternalLink, isInternalActionLink, isInternalLink } from 'utils/link';
import { PageModuleTypeProp } from '../PageModule.types';
import { useAuthDialog } from 'components/common/AuthDialog/useAuthDialog';
import Image from 'next/image';
import { phrases } from 'constants/phrases';

export const ChallengeBanner = ({ module, name }: { module: PageModuleTypeProp<'ChallengeBanner'>; name: string }) => {
  const router = useRouter();
  const challenge = useLatestHiberChallengeQuery();
  const showButton = module?.buttonText && module?.projectIdOrLink;
  const session = useGetOrCreateDaoSession();
  const { trackUserInteract } = useDive();
  const pathname = usePathname() ?? '';
  const auth = useAuthDialog();

  if (challenge.loading) {
    return <Spinner />;
  }

  if (!challenge.data?.latestHiberChallenge) {
    return null;
  }

  const { latestHiberChallenge } = challenge.data;

  const navigate = () => {
    const { projectIdOrLink } = module;

    if (!projectIdOrLink) {
      return;
    }
    trackUserInteract(projectIdOrLink, pathname, name, 'banner', latestHiberChallenge.title);

    if (isInternalActionLink(projectIdOrLink)) {
      const url = new URLSearchParams(projectIdOrLink.slice(1));

      if (url.get('action') === 'sign-up') {
        auth.open('SIGN_UP', {
          description: phrases.authDialog.signUp.defaultHeadline,
          name: 'sign_up',
          origin: 'banner',
        });
        return;
      }
    }

    if (isInternalLink(projectIdOrLink)) {
      router.push(projectIdOrLink);
      return;
    }
    if (isExternalLink(projectIdOrLink)) {
      window.open(projectIdOrLink);
      return;
    }
    session.loadAndJoinSession(projectIdOrLink);
  };

  return (
    <div className="overflow-hidden relative h-[400px] w-full flex items-center rounded-lg px-4 py-0 medium:py-16 large:py-32">
      <Image className="object-cover object-center" src={latestHiberChallenge.thumbnail.url} fill alt="" />
      <div className="absolute max-w-[320px] small:max-w-[450px]">
        <Heading2>{latestHiberChallenge.title}</Heading2>
        <div style={{ maxWidth: '400px' }}>
          <Paragraph size="small">{latestHiberChallenge.description}</Paragraph>
        </div>
        {showButton && <Button variety="primary" size="medium" text={module.buttonText ?? ''} onClick={navigate} />}
      </div>
    </div>
  );
};

export default ChallengeBanner;
