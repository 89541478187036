import Link from 'next/link';
import Base64 from 'utils/Base64';
import * as S from './Links.styles';
import { ProjectLinkProps } from './Links.types';

export const ProjectLink = ({ id, children, overrideColor, bold, onLinkClick }: ProjectLinkProps) => {
  return (
    <div onClick={onLinkClick}>
      <Link href={`/world/${Base64.fromNumber(id)}`} passHref legacyBehavior prefetch={false}>
        <S.Container data-testid="project-link" bold={bold} overrideColor={overrideColor}>
          {children}
        </S.Container>
      </Link>
    </div>
  );
};
