import { LoadingCard } from 'components/common/SkeletonLoader';
import { Spacer } from 'components/layouts/Spacer';

export const SessionCardLoader = () => {
  return (
    <div className="flex">
      <div className="flex flex-col">
        <LoadingCard height={256} width={196} borderRadius={8} />
        <Spacer width={8} height={10} />
        <LoadingCard width={120} height={16} borderRadius={8} />
        <Spacer width={16} height={10} />
      </div>
      <Spacer width={16} height={0} />
    </div>
  );
};
