import { Button } from 'components/common/buttons/Button';
import { Heading1, Paragraph } from 'components/typography';
import { BannerLayout } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useGetOrCreateDaoSession } from 'hooks/useGetOrCreateDaoSession';
import Image from 'next/image';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import Base64 from 'utils/Base64';
import { PageModuleTypeProp } from '../PageModule.types';
import { isInternalActionLink } from 'utils/link';
import { useAuthDialog } from 'components/common/AuthDialog/useAuthDialog';
import { phrases } from 'constants/phrases';

export const Banner = ({
  module,
  name,
  imageHasPriority = false,
}: {
  module: PageModuleTypeProp<'Banner'>;
  name: string;
  imageHasPriority?: boolean;
}) => {
  const { header, text, background, buttonLabel, projectIdOrButtonLink, bannerLayout } = module;
  const router = useRouter();
  const pathname = usePathname() ?? '';
  const session = useGetOrCreateDaoSession();
  const [width, setWidth] = useState(0);
  const { trackUserInteract } = useDive();
  const layout = bannerLayout ?? BannerLayout.Large;
  const isSmallBanner = layout === BannerLayout.Small;
  const auth = useAuthDialog();

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const handleButton = () => {
    const isExternalLink = projectIdOrButtonLink?.startsWith('https://');
    const isInternalLink = projectIdOrButtonLink?.startsWith('/');

    trackUserInteract(projectIdOrButtonLink, pathname, name, 'banner', '');

    if (isInternalActionLink(projectIdOrButtonLink)) {
      const url = new URLSearchParams(projectIdOrButtonLink.slice(1));

      if (url.get('action') === 'sign-up') {
        auth.open('SIGN_UP', {
          description: phrases.authDialog.signUp.defaultHeadline,
          name: 'sign_up',
          origin: 'banner',
        });
        return;
      }
    }

    if (isInternalLink && projectIdOrButtonLink) {
      router.push(projectIdOrButtonLink);
      return;
    }
    if (isExternalLink && projectIdOrButtonLink) {
      window.open(projectIdOrButtonLink, '_blank');
      return;
    }

    session.loadAndJoinSession(Base64.toNumber(projectIdOrButtonLink).toString());
  };

  const titleHasLongWords = header
    .split(' ')
    .map(word => word.length > 9)
    .some(isLong => isLong);

  const smallerHeadline = isSmallBanner || (width < 450 && titleHasLongWords);

  return (
    <div
      data-large={layout === BannerLayout.Large}
      className="overflow-hidden relative w-full h-[auto] data-[large=true]:h-[400px] flex items-center rounded-lg">
      <div className="h-full w-full absolute">
        <div className="p-4 z-[1] absolute h-full w-full bg-gradient-to-r from-[rgba(0,0,0,0.4)] to-[rgba(255,255,255,0)]" />
        <Image
          className="object-cover object-center"
          src={background?.url ?? ''}
          fill
          alt="Banner image"
          priority={imageHasPriority}
        />
      </div>
      <div
        className="mx-8 relative py-[1.5rem] z-[2] data-[large=true]:max-w-[450px]
data-[large=false]:w-full data-[large=false]:flex data-[large=false]:items-start
data-[large=false]:flex-col data-[large=false]:mediumSmall:justify-between data-[large=false]:mediumSmall:flex-row data-[large=false]:mediumSmall:items-end
large:mx-16"
        data-large={layout === BannerLayout.Large}>
        <div>
          {smallerHeadline ? (
            <Heading1 className={`text-h2 ${isSmallBanner ? 'max-w-[350px]' : ''}}`}>{header}</Heading1>
          ) : (
            <Heading1>{header}</Heading1>
          )}
          {text && (
            <div style={{ maxWidth: '80%' }}>
              <div className="h-4" />
              <Paragraph size="medium">{text}</Paragraph>
            </div>
          )}
        </div>
        <div className="h-4" />
        <div className="flex items-end h-full">
          <Button
            variety="primary"
            size="medium"
            onClick={e => {
              e.stopPropagation();
              handleButton();
            }}
            disabled={session.loading}
            busy={session.loading}>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
