import { Button } from 'components/common/buttons/Button';
import { Heading1, Heading2, Paragraph } from 'components/typography';
import { Hero as HeroType } from 'generated/graphql';
import { useGetOrCreateDaoSession } from 'hooks/useGetOrCreateDaoSession';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import Base64 from 'utils/Base64';
import { isExternalLink, isInternalActionLink, isInternalLink } from 'utils/link';
import { useAuthDialog } from 'components/common/AuthDialog/useAuthDialog';
import { phrases } from 'constants/phrases';

export const Hero = ({ module }: { module: HeroType }) => {
  const { preHeader, header, buttonLabel, projectIdOrButtonLink, background, subHeader } = module;
  const router = useRouter();
  const session = useGetOrCreateDaoSession();
  const auth = useAuthDialog();

  const handleButton = () => {
    if (isInternalActionLink(projectIdOrButtonLink)) {
      const url = new URLSearchParams(projectIdOrButtonLink.slice(1));

      if (url.get('action') === 'sign-up') {
        auth.open('SIGN_UP', {
          description: phrases.authDialog.signUp.defaultHeadline,
          name: 'sign_up',
          origin: 'hero_banner',
        });
        return;
      }
    }

    if (isInternalLink(projectIdOrButtonLink)) {
      router.push(projectIdOrButtonLink);
      return;
    }
    if (isExternalLink(projectIdOrButtonLink)) {
      window.location.href = projectIdOrButtonLink;
      return;
    }

    session.loadAndJoinSession(Base64.toNumber(projectIdOrButtonLink).toString());
  };

  return (
    <div className="flex mb-4 relative overflow-hidden justify-center [max-height:70vh] [height:50vh] w-full">
      <Image
        className="w-full object-cover [object-position:0%40%]"
        src={background?.url ?? ''}
        alt="Preview of HiberWorld"
        fill
        priority
      />
      <div className="absolute w-full h-full opacity-100 bg-gradient-to-r from-greyscale-hiberBlack/50 to-greyscale-almostBlack/15" />
      <div className="flex flex-col items-start justify-center py-0 px-8 small:px-16 absolute w-full h-full max-w-screen-xlarge">
        <Heading2 className="text-h3 small:text-h2">{preHeader}</Heading2>
        <Heading1 className="text-h2 small:text-h1">{header}</Heading1>
        <div className="h-1 small:h-4" />
        <Paragraph size="large" className="max-w-[340px]">
          {subHeader}
        </Paragraph>
        <div className="h-4" />

        <Button
          variety="primary"
          size="large"
          busy={session.loading}
          disabled={session.loading}
          onClick={handleButton}
          text={buttonLabel}
        />
      </div>
    </div>
  );
};

export default Hero;
