import React, { useEffect, useRef, useState, Children } from 'react';
import Image from 'next/image';

import { Chevron } from '@hiberworld/icons';
import { twc } from 'react-twc';
import { config } from 'config';

const FallbackImage = () => {
  return (
    <Image
      src={config.cdn + '/avatar/RPM/rpm-avatars-placeholder.png'}
      alt="select a readyplayerme avatar"
      fill
      style={{ objectFit: 'contain' }}
    />
  );
};

const NavigationButton = twc(Chevron)(({ rotated }: { onClick: () => void; rotated?: boolean }) => [
  `flex items-center justify-center text-greyscale-mediumGrey w-8 h-8 cursor-pointer hover:text-greyscale-white rotate-90`,
  rotated && `rotate-[270deg]`,
]);

const NavigationDots = ({ selectedSlide, handleNavDotClick, elementsToRender }) => (
  <div className="flex flex-row justify-center py-2 mt-4 items-center gap-5 ">
    {elementsToRender.map((element, index) => {
      return (
        <div
          key={'nav-dot-' + element.key}
          data-selected={index === selectedSlide}
          className="rounded-full w-[12px] h-[12px] cursor-pointer border border-solid border-greyscale-mediumGrey
                data-[selected=true]:bg-greyscale-lightGrey bg-greyscale-dark hover:bg-greyscale-lightGrey z-[1]"
          onClick={e => {
            e.stopPropagation();
            handleNavDotClick(index);
          }}
        />
      );
    })}
  </div>
);

const SlideContainer = ({
  carouselRef,
  children,
  nextSlide,
  selectedSlide,
  carouselSize,
  handleMouseEnter,
  handleMouseLeave,
  navigationDotsPosition,
  handleNavDotClick,
}) => {
  const elementsToRender = Children.toArray(children);
  return (
    <div className="w-full h-full rounded-md overflow-hidden relative">
      <div ref={carouselRef} className="w-full h-full absolute rounded-md flex transition-all duration-300 ">
        {elementsToRender.map((element, index) => (
          <div
            key={'carousel-slide-' + index}
            className="relative shrink-0 w-full h-full"
            onClick={() => nextSlide(selectedSlide)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              left: -selectedSlide * carouselSize.width,
            }}>
            {element}
            {navigationDotsPosition && navigationDotsPosition === 'inside' && (
              <div className="absolute bottom-0 w-full">
                <NavigationDots
                  elementsToRender={elementsToRender}
                  selectedSlide={selectedSlide}
                  handleNavDotClick={handleNavDotClick}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

type CarouselData = {
  image: string;
  video: string;
  title: string;
  text?: string;
};

export type DiveData = {
  component: 'slide' | 'navDot';
  tracking: ({ index }: { index: number }) => void;
};

export type CarouselProps = {
  data?: CarouselData[] | undefined;
  showNavigationArrows?: boolean;
  navigationDotsPosition?: 'inside' | 'outside';
  autoplayIntervalSeconds?: number;
  pauseOnHover?: boolean;
  children?: React.ReactNode;
  diveTracking?: DiveData[];
};

const CarouselComponent = ({
  children,
  showNavigationArrows,
  autoplayIntervalSeconds = 0,
  pauseOnHover = false,
  diveTracking,
  navigationDotsPosition,
}: CarouselProps) => {
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [carouselSize, setCarouselSize] = useState({ width: 0, height: 0 });
  const carouselRef = useRef(null);
  const [paused, setPaused] = useState(false);
  const elementsToRender = Children.toArray(children);

  const updateCarouselSize = () => {
    const elem = carouselRef.current as unknown as HTMLDivElement;
    const { width, height } = elem.getBoundingClientRect();
    if (carouselRef.current) {
      setCarouselSize({
        width,
        height,
      });
    }
  };

  // useEffect to get the initial carousel size
  useEffect(() => {
    updateCarouselSize();

    window.addEventListener('resize', updateCarouselSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateCarouselSize);
    };
  }, []);

  const nextSlide = (selectedSlide: number) => {
    if (selectedSlide === elementsToRender.length - 1) {
      setSelectedSlide(0);
    } else {
      setSelectedSlide(prev => prev + 1);
    }
  };
  const previousSlide = (selectedSlide: number) => {
    if (selectedSlide === 0) {
      setSelectedSlide(elementsToRender.length - 1);
    } else {
      setSelectedSlide(prev => prev - 1);
    }
  };

  useEffect(() => {
    if (autoplayIntervalSeconds && !paused) {
      const interval = setInterval(() => {
        nextSlide(selectedSlide);
      }, autoplayIntervalSeconds * 1000);
      return () => clearInterval(interval);
    }
  }, [autoplayIntervalSeconds, selectedSlide, paused]);

  const onHandleMouseEnterSlide = () => {
    if (pauseOnHover) setPaused(true);
  };
  const onHandleMouseLeaveSlide = () => {
    if (pauseOnHover) setPaused(false);
  };

  const handleClickPreviousSlideButton = () => {
    previousSlide(selectedSlide);
  };

  const handleClickNextSlideButton = () => {
    nextSlide(selectedSlide);
  };

  const handleNextSlideCardClick = () => {
    const diveTrackingForElement = diveTracking?.find(element => element.component === 'slide')?.tracking;
    diveTrackingForElement?.({ index: selectedSlide });
    nextSlide(selectedSlide);
  };

  const handleNavDotClick = (index: number) => {
    const diveTrackingForElement = diveTracking?.find(element => element.component === 'navDot')?.tracking;
    diveTrackingForElement?.({ index });
    setSelectedSlide(index);
  };

  return (
    <div className="flex flex-col justify-center items-center rounded-md w-full h-full ">
      <div className="flex flex-row justify-center items-center rounded-md w-full h-full">
        {showNavigationArrows && <NavigationButton onClick={handleClickPreviousSlideButton} rotated />}
        <SlideContainer
          carouselRef={carouselRef}
          nextSlide={handleNextSlideCardClick}
          selectedSlide={selectedSlide}
          handleNavDotClick={handleNavDotClick}
          carouselSize={carouselSize}
          handleMouseEnter={onHandleMouseEnterSlide}
          handleMouseLeave={onHandleMouseLeaveSlide}
          navigationDotsPosition={navigationDotsPosition}>
          {children}
        </SlideContainer>
        {showNavigationArrows && <NavigationButton onClick={handleClickNextSlideButton} />}
      </div>
      {navigationDotsPosition && navigationDotsPosition === 'outside' && (
        <NavigationDots
          elementsToRender={elementsToRender}
          selectedSlide={selectedSlide}
          handleNavDotClick={handleNavDotClick}
        />
      )}
    </div>
  );
};
export const Carousel = ({
  children,
  showNavigationArrows,
  pauseOnHover,
  autoplayIntervalSeconds,
  diveTracking,
  navigationDotsPosition,
}: CarouselProps) => {
  return !children ? (
    <FallbackImage />
  ) : (
    <CarouselComponent
      showNavigationArrows={showNavigationArrows}
      autoplayIntervalSeconds={autoplayIntervalSeconds}
      pauseOnHover={pauseOnHover}
      diveTracking={diveTracking}
      navigationDotsPosition={navigationDotsPosition}>
      {children}
    </CarouselComponent>
  );
};
