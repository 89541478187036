import { Play } from '@hiberworld/icons';
import { useGetOrCreateDaoSession } from 'hooks/useGetOrCreateDaoSession';
import Link from 'next/link';
import Base64 from 'utils/Base64';
import { OnlinePlayers } from './OnlinePlayers';
import { Label } from 'components/typography';
import { Button } from 'components/common/buttons/Button';
import Image from 'next/image';
import { Spinner } from 'components/common/spinners/Spinner';

type SessionCardProps = {
  projectId: string;
  projectTitle: string;
  thumbnailUrl: string;
  label: string;
  onSessionClick?: () => void;
  onProjectClick?: () => void;
  imageHasPriority?: boolean;
  dotColor?: 'red' | 'green';
};

export const SessionCard = ({
  label,
  projectTitle,
  projectId,
  thumbnailUrl,
  onSessionClick,
  onProjectClick,
  imageHasPriority = false,
  dotColor,
}: SessionCardProps) => {
  const { loadAndJoinSession, loading } = useGetOrCreateDaoSession();

  const handleCardClick = () => {
    if (loading) {
      return;
    }
    loadAndJoinSession(projectId);
    onSessionClick?.();
  };

  return (
    <div className="pb-2 gap-2 flex flex-col text-greyscale-white" data-testid="session-card">
      <div
        className="w-[192px] cursor-pointer flex-shrink-0 relative flex mr-4 overflow-hidden rounded-lg"
        onClick={handleCardClick}
        role="button"
        data-testid="session-card-join">
        <Image
          className="overflow-hidden h-256 w-auto object-cover saturate-[140%] contrast-[110%] brightness-[105%] transition-all ease-in-out hover:duration-250 hover:scale-110"
          src={thumbnailUrl}
          alt="Click to join"
          height={256}
          width={455}
          data-testid="image-background"
          priority={imageHasPriority}
        />
        <div className="flex flex-col w-full h-full absolute justify-between pointer-events-none">
          <OnlinePlayers label={label} color={dotColor} />
          <div className="flex pb-2 justify-center">
            <Button
              className="flex items-center justify-center w-[74px] py-2 rounded-xl h-[24px] min-h-[unset] pointer-events-auto text-greyscale-white"
              variety="primary"
              size="small"
              Icon={<Play />}
              spinner={<Spinner white tiny />}
              busy={loading}
              text="Join"
              disabled={loading}
            />
          </div>
        </div>
      </div>

      <div className="max-w-[192px] overflow-hidden text-ellipsis">
        <Link href={`/world/${Base64.fromNumber(projectId)}`} passHref prefetch={false} title={projectTitle}>
          <Label
            className="mt-2 whitespace-nowrap text-greyscale-white hover:cursor-pointer"
            size="large"
            onClick={onProjectClick}>
            {projectTitle}
          </Label>
        </Link>
      </div>
    </div>
  );
};
