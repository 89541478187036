import styled from 'styled-components';
import { LinkStyleProps } from './Links.types';

export const Container = styled.div<LinkStyleProps>`
  color: white;
  ${({ overrideColor }) => (overrideColor ? `color: ${overrideColor}!important;` : '')}
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  &:hover {
    cursor: pointer;
  }
`;
