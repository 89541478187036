import { Carousel as CarouselType } from 'generated/graphql';
import { Carousel, DiveData } from 'components/common/Carousel/Carousel';
import { Banner } from '../Banner/Banner';
import { useDive } from 'hooks/useDive';
import { usePathname } from 'next/navigation';

export const CarouselBanner = ({
  module,
  shouldRenderServerSide,
}: {
  module: CarouselType;
  shouldRenderServerSide: boolean;
}) => {
  const { id, items } = module;
  const dive = useDive();
  const pathname = usePathname();

  const trackingForClickNextSlide = ({ index }: { index: number }) => {
    dive.track('userInteract', {
      page_id: pathname,
      page_id_before: '',
      type: 'banner',
      name: `${index}_banner_click_next`,
    });
  };
  const trackingForClickNavDot = ({ index }: { index: number }) => {
    dive.track('userInteract', {
      page_id: pathname,
      page_id_before: '',
      type: 'banner',
      name: `banner_circle_click_${index}`,
    });
  };

  const diveTracking: DiveData[] = [
    {
      component: 'slide',
      tracking: trackingForClickNextSlide,
    },
    {
      component: 'navDot',
      tracking: trackingForClickNavDot,
    },
  ];

  return (
    <div
      key={id}
      className="max-h-[80vh] h-[400px] w-full max-w-[1305px] overflow-hidden relative flex items-center justify-center">
      <Carousel autoplayIntervalSeconds={4} pauseOnHover navigationDotsPosition={'inside'} diveTracking={diveTracking}>
        {items?.map((banner, index) => {
          const name = `${index}_${banner.header}`;
          return (
            <Banner
              key={name}
              module={{ ...banner, __typename: 'Banner' }}
              name={name}
              imageHasPriority={shouldRenderServerSide}
            />
          );
        })}
      </Carousel>
    </div>
  );
};

export default CarouselBanner;
