import { LoadingCard, TextPlaceholder } from 'components/common/SkeletonLoader';
import { useTheme } from 'styled-components';

export const NewsFeedLoader = () => {
  const theme = useTheme();

  return (
    <div className="flex medium:flex-col medium:w-full gap-4 ml-16" aria-label="loading...">
      <div className="flex medium:flex-col medium:w-full w-1/2">
        <LoadingCard width="100%" height="320px" borderRadius={8} />
      </div>
      <div className="flex medium:flex-col medium:w-full w-1/2">
        <div className="flex flex-col w-full gap-4 justify-center">
          <TextPlaceholder width="80%" height={theme.typography.header.h2.lineHeight} marginBottom="1.5rem" />
          <TextPlaceholder width="60%" height={theme.typography.body.small.lineHeight} />
          <TextPlaceholder width="70%" height={theme.typography.body.small.lineHeight} />
          <TextPlaceholder width="55%" height={theme.typography.body.small.lineHeight} />
          <TextPlaceholder width="25%" height="3rem" marginTop="1.5rem" />
        </div>
      </div>
    </div>
  );
};
