import { ProjectCard } from 'components/cards/ProjectCard';
import { ErrorMessage } from 'components/typography';
import { Project, useProjectsByIdsQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { ENGINE_TRACK_URL } from 'hooks/useDive/useDive.constants';
import { usePathname } from 'next/navigation';
import Base64 from 'utils/Base64';
import { PageModuleTypeProp } from '../PageModule.types';
import { FeedHeading } from '../shared/FeedHeading';
import { ProjectsGrid } from './ProjectsGrid';
import { ProjectFeedLoader } from './ProjectFeedLoader';

export const ProjectFeedSpecificModule = ({
  module,
  feedIndex,
}: {
  module: PageModuleTypeProp<'ProjectFeedSpecific'>;
  feedIndex: number;
}) => {
  const projectIds = module.projectIds.map(id => Base64.toNumber(id).toString());
  const { loading, error, data } = useProjectsByIdsQuery({
    variables: { projectIds },
  });
  const pathname = usePathname() ?? '';
  const dive = useDive();

  const trackProjectCardClick = (projectId: string, destination: string, name) => {
    dive.track('userInteract', {
      page_id: destination,
      page_id_before: pathname,
      name,
      type: 'feed',
      id: projectId,
    });
  };

  if (loading) {
    return <ProjectFeedLoader quantity={projectIds.length} layout={module.layout} />;
  }

  if (error) {
    return <ErrorMessage message={error?.message} />;
  }

  const projectsByIds = data?.projectsByIds;

  // Projects are not fetch in the order of the ids, so this sorts them correctly again
  const sortedProjects = (projectIds || [])
    .map(id => projectsByIds?.find((project: Project) => project.id === id))
    .filter((project: Project | undefined): project is Project => project !== undefined);

  const slug = module.page?.slug;
  const viewMoreLink = slug ? `/feed/${slug}` : undefined;

  if (sortedProjects.length > 0) {
    return (
      <>
        <FeedHeading heading={module.title} viewMoreLink={viewMoreLink} />
        <ProjectsGrid layout={module.layout}>
          {sortedProjects.map((project, index) => {
            const name = feedIndex + '_' + module.title + '_' + index;
            return (
              <ProjectCard
                project={project}
                key={project.id}
                playOnClick={module.directPlayOnClick}
                showAuthor={Boolean(module.showAuthor)}
                onProjectClick={() =>
                  trackProjectCardClick(
                    project.id,
                    module.directPlayOnClick ? ENGINE_TRACK_URL : `/world/${Base64.fromNumber(Number(project.id))}`,
                    name
                  )
                }
                onAuthorClick={() => trackProjectCardClick(project.id, `/user/${project.author.username}}`, name)}
              />
            );
          })}
        </ProjectsGrid>
      </>
    );
  }

  return null;
};

export default ProjectFeedSpecificModule;
