import { fade } from 'components/common/SkeletonLoader/Skeleton.styles';
import styled from 'styled-components';

export const OuterRow = styled.div`
  max-width: 1420px;
  margin: auto;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 1rem;
`;

export const Card = styled.div`
  height: 150px;
  max-width: 243px;
  min-width: 204px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  flex: 0 0;
  background-color: ${({ theme }) => theme.primaryColor.appDarkGrayLighter};
`;

export const SkeletonCard = styled(Card)`
  animation: ${fade} 1.2s infinite ease;
`;

type PlayingCardProps = {
  thumbnail?: { medium: { url: string } } | null;
};
export const PlayingCard = styled(Card)<PlayingCardProps>`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      0deg,
      rgba(25, 26, 29, 0.15) 0%,
      rgba(25, 26, 29, 0.5) 40%,
      rgba(25, 26, 29, 0.8) 100%
    ),
    url('${({ thumbnail }) => thumbnail?.medium?.url ?? ''}');
  justify-content: space-between;
`;

export const ProfilePicture = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`;

export const BigProfilePicture = styled.img`
  height: 64px;
  width: 64px;
  border-radius: 50%;
`;

export const InfoRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  gap: 8px;
  height: 32px;
`;

export const Username = styled.div`
  color: white;
  font-weight: bold;
  font-size: 16px;
  margin: 0px;
`;

export const SubTitle = styled.p`
  color: white;
  font-size: 0.75rem;
  margin: 0px;
`;

export const Title = styled.p`
  color: white;
  font-weight: bold;
  margin: 0px;
  font-size: 16px;
`;

export const OnlineCard = styled(Card)`
  justify-content: space-evenly;
`;
